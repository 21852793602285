export const globalVariables = {
  getFuelCoupons: "/v1/coupons",
  downloadFuelCoupons: "/coupons",
  getFuelTypes: "/v1/fuel-types",
  getFuelPrices: "/v1/fuel-prices",
  getFuelSales: "/v1/sales",
  getFuelSalesCountsRoute: "/v1/sales-counts",
  downloadFuelSales: "/v1/download-sales",
  getFuelBranches: "/v1/branches",
  getFuelCompanies: "/v1/companies",
  downloadFuelDiscounts: "/v1/download-discounts",
  getFuelDiscounts: "/v1/discounts",
  getFuelPayments: "/v1/transactions",
  getFuelCouponsCountsRoute: "/v1/coupons-counts",
};
