import axios from "axios";
import { getCookie } from "./utils";
import { AUTH_MS_URL, FUEL_BACKOFFICE_MS_URL, RIDE_MS_URL } from "./constants";

const token = getCookie("oya_token");

export const apiHeaders = (type = "") => {
  const token = getCookie("oya_token") || "";
  if (type === "file") {
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  }
};

export const base_api_requests = axios.create({
  baseURL: `${RIDE_MS_URL}`,

  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const base_auth_requests = axios.create({
  baseURL: `${AUTH_MS_URL}`,

  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getFuelBackOfficeMSApi = async (
  path: string,
  params = {},
  options = {}
) => {
  let headers = apiHeaders();
  headers = { ...headers, ...options };
  const url = `${FUEL_BACKOFFICE_MS_URL}${path}`;
  return axios({
    method: "GET",
    url,
    headers,
    params,
  })
    .then((response) => {
      const res = response.data;
      return res;
    })
    .catch((error) => {
      return { status: 606, result: "Network request failed", error };
    });
};
