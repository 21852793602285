import { logout } from "../helpers/utils";
import unAuthImg from "../images/noAccessImg.svg";
import { Button } from "@material-tailwind/react";

const UnAuthorizedAccess = () => {
  return (
    <>
      <div className="flex justify-center items-center h-screen overflow-hidden">
        <div className="bg-white border md:w-1/3 p-5 rounded-md">
          <p className="text-center font-semibold text-lg">
            Unauthorized Access. Please contact the Admin
          </p>
          <img
            className="flex mr-auto ml-auto mt-4 w-[200px]"
            src={unAuthImg}
            alt="unauth"
          />

          <div className="mt-5 flex justify-center">
            <Button placeholder="Logout" color="red" onClick={() => logout()}>
              Logout
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnAuthorizedAccess;
