import { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  Bars3Icon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import logoImg from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { IoCalendarNumberOutline } from "react-icons/io5";

interface menuActiveProps {
  fuelTypesActive?: string;
  fuelPricesActive?: string;
}

const CustomSideBar = ({
  fuelTypesActive,
  fuelPricesActive,
}: menuActiveProps) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isToggledOnMobile, setIsToggledOnMobile] = useState(false);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const handleDeviceType = () => {
      const width = window.innerWidth;
      if (width < 768) {
        //mobile
        setIsCollapsed(true);
        setIsMobile(true);
      } else if (width >= 768 && width < 1024) {
        //tablet
        setIsCollapsed(true);
        setIsMobile(true);
      } else {
        //desktop
        setIsCollapsed(false);
        setIsMobile(false);
      }
    };

    handleDeviceType();

    window.addEventListener("resize", handleDeviceType);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleDeviceType);
    };
  }, []);

  //handleMobileMenuToggle
  const handleMobileMenuToggle = () => {
    setIsCollapsed(true);
    setIsMobile(false);
    setIsToggledOnMobile(true);
  };
  //handleMobileMenuClose
  const handleMobileMenuClose = () => {
    setIsCollapsed(false);
    setIsMobile(true);
    setIsToggledOnMobile(false);
  };

  return (
    <>
      <div className="absolute left-4 top-5 lg:hidden">
        <button
          className="h-10 w-10 border-[1px] border-gray-500 flex justify-center items-center rounded-[5px]"
          onClick={() => handleMobileMenuToggle()}
        >
          <Bars3Icon className="h-5 w-5 text-gray-500" />
        </button>
      </div>
      {isToggledOnMobile && (
        <div className="absolute left-20 top-5 lg:hidden">
          <button
            className="h-10 w-10 border-[1px] border-gray-500 flex justify-center items-center rounded-[5px]"
            onClick={() => handleMobileMenuClose()}
          >
            <XMarkIcon className="h-5 w-5 text-gray-500" />
          </button>
        </div>
      )}

      <Sidebar
        collapsed={isCollapsed}
        className={`${isMobile ? "hidden" : "block"}`}
      >
        <Menu className="bg-white h-full">
          {/* logo */}
          <div className="p-3 flex items-center gap-3 w-full">
            <Link className="w-full" to="#">
              <img src={logoImg} alt="logo" />
            </Link>
            <p
              className={`${isCollapsed ? "hidden" : ""} font-semibold text-xl`}
            >
              OYA!
            </p>
            <div
              className={`${
                isCollapsed ? "hidden" : "flex justify-end items-center w-full"
              }`}
            >
              <ChevronDoubleLeftIcon
                className="w-5 h-5 cursor-pointer"
                onClick={() => handleCollapse()}
              />
            </div>
          </div>
          <ChevronDoubleRightIcon
            className={`${
              isCollapsed
                ? "w-5 h-5 cursor-pointer my-3 flex mr-auto ml-auto"
                : "hidden"
            }`}
            onClick={() => handleCollapse()}
          />

          <div className="mt-2">
            <MenuItem
              icon={<IoCalendarNumberOutline className="h-5 w-5" />}
              className={fuelTypesActive}
              onClick={() => navigate("/")}
            >
              <p>Fuel Types</p>
            </MenuItem>
            <MenuItem
              icon={<IoCalendarNumberOutline className="h-5 w-5" />}
              className={fuelPricesActive}
              onClick={() => navigate("/fuel-prices")}
            >
              <p>Fuel Prices</p>
            </MenuItem>
          </div>
        </Menu>
      </Sidebar>
    </>
  );
};

export default CustomSideBar;
