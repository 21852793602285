import { useEffect, useMemo, useState } from "react";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import MaterialReactTable from "material-react-table";
import PageLayout from "../../components/layouts/pageLayout";
import { Typography } from "@mui/material";
import { FUEL_BACKOFFICE_MS_URL } from "../../helpers/constants";
import axios from "axios";
import { getCookie } from "../../helpers/utils";

const FuelTypes = () => {
  const token = getCookie("oya_token");
  const [hideFilter, setHideFilter] = useState<Boolean>(true);
  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const fetchTableData = async () => {
    setIsLoading(true);
    try {
      const offset = pagination.pageIndex * pagination.pageSize;
      const { data } = await axios.get(
        `${FUEL_BACKOFFICE_MS_URL}/v1/fuel-types?limit=${pagination.pageSize}&offset=${offset}&sorting=created_at:desc`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(data?.payload?.items || []);
      setRowCount(data?.payload?.total);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      throw new Error("Error fetching data");
    }
  };

  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex, pagination.pageSize]);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) =>
          moment(row?.created_at).format("Do MMM YYYY - hh:mm a"),
        id: "created_at",
        header: "Date Added",
        size: 200,
      },
      {
        accessorFn: (row: any) => row?.name,
        id: "name",
        header: "Name",
        size: 100,
      },
      {
        accessorFn: (row: any) => row?.code,
        id: "code",
        header: "Code",
        size: 100,
      },
    ],
    []
  );
  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div className="h-screen overflow-hidden">
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">Fuel Types</Typography>
              <ChevronDoubleLeftIcon
                className={`${
                  hideFilter ? "block" : "block lg:hidden"
                } h-5 w-5 cursor-pointer`}
                onClick={() => handleFilterHide()}
              />
            </div>
            <div className="lg:grid lg:grid-cols-4 mt-2 gap-5"></div>

            <div className="grid grid-cols-1 mt-3">
              <div className="mt-3 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={data}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    showProgressBars: isLoading,
                    pagination,
                    density: "compact",
                    isLoading: isLoading,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default FuelTypes;
