import UserProfile from "../general/profile";



const CustomNavbar = () => {

  return (
    <div className="w-full px-4 py-2 shadow-md">
      <div className="flex items-center justify-between text-blue-gray-900">
        {/* left */}
        <div></div>

        {/* middle field */}
        <div className="hidden lg:block"></div>

        {/* right */}

        <UserProfile />
      </div>
    </div>
  );
};

export default CustomNavbar;
