import { useEffect, useState } from "react";
import { ACCOUNTS_URL, FUEL_BACKOFFICE_MS_URL } from "../helpers/constants";
import { getCookie } from "../helpers/utils";
import { Spinner } from "@material-tailwind/react";
import { notification } from "antd";
import axios from "axios";
import UnAuthorizedAccess from "../pages/unAuthorizedAccess";
import { useNavigate } from "react-router-dom";
import FuelTypes from "../pages/fuelTypes";

const ProtectedRoutes = ({ children }: any) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isChecking, setIsChecking] = useState(true);
  const navigate = useNavigate();

  const token = getCookie("oya_token");
  const selectedService = getCookie("oya_selected_service");

  useEffect(() => {
    const checkRoles = async () => {
      setIsChecking(true);
      try {
        const { data } = await axios.get(
          `${FUEL_BACKOFFICE_MS_URL}/v1/me/assigned-roles`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const assignedRoles = data?.payload?.items;

        // Check if the user has an authorized role
        const authorizedRoles = ["ADMIN", "SUPER_ADMIN"];

        const hasAuthorizedRole = assignedRoles
          .map((assignedRole: any) =>
            authorizedRoles.includes(assignedRole?.role?.code)
          )
          .includes(true);

        if (hasAuthorizedRole) {
          setIsChecking(false);
          setIsAuthorized(true);
        } else {
          setIsChecking(false);
          setIsAuthorized(false);
        }
      } catch (error: any) {
        notification.error({
          message:
            error?.response?.data?.message ??
            "Error while fetching branch roles",
        });
        navigate("unauthorized", {
          replace: true,
        });
      }
    };

    checkRoles();
    // eslint-disable-next-line
  }, []);

  if (token) {
    if (isChecking) {
      return (
        <div className="flex justify-center items-center h-screen">
          <Spinner className="h-10 w-10" />
        </div>
      );
    } else {
      if (isAuthorized) {
        if (selectedService) {
          return children;
        } else {
          return <FuelTypes />;
        }
      } else {
        return <UnAuthorizedAccess />;
      }
    }
  } else {
    return (window.location.href = `${ACCOUNTS_URL}/login?appref=${window.location.href}`);
  }
};

export default ProtectedRoutes;
