import "./css/App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { UserProvider } from "./context/userContext";
import ProtectedRoutes from "./routes/protected-route";
import UnAuthorizedAccess from "./pages/unAuthorizedAccess";
import FuelTypes from "./pages/fuelTypes";
import FuelPrices from "./pages/fuelPrices";

function App() {
  return (
    <>
      <UserProvider>
        <Router>
          <Routes>
            <Route path="unauthorized" element={<UnAuthorizedAccess />} />
            <Route
              path="/fuel-prices"
              element={
                <ProtectedRoutes>
                  <FuelPrices />
                </ProtectedRoutes>
              }
            />
            {/* protected routes start */}
            <Route
              path="/"
              element={
                <ProtectedRoutes>
                  <FuelTypes />
                </ProtectedRoutes>
              }
            />
            <Route path="*" element={<h4>Page not found!</h4>} />

            {/* protected routes end */}
          </Routes>
        </Router>
      </UserProvider>
    </>
  );
}

export default App;
